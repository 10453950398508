import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    CardContent,
    Chip,
    Collapse,
    IconButton,
    Stack,
    SvgIconProps,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AttachMoneyOutlined,
    BadgeOutlined,
    BuildCircle,
    BuildOutlined,
    CardGiftcard,
    DateRangeOutlined,
    DirectionsBoatOutlined,
    DirectionsCarOutlined,
    DirectionsTransitOutlined,
    Edit,
    ErrorOutline,
    ExploreOutlined,
    FlightOutlined,
    FolderSpecialOutlined,
    HealthAndSafetyOutlined,
    HotelOutlined,
    ImportContactsOutlined,
    Info,
    InventoryOutlined,
    MarginOutlined,
    PeopleOutlineOutlined,
    PointOfSaleOutlined,
    QrCodeOutlined,
    ReportProblemOutlined,
    RestaurantOutlined,
    RoomOutlined,
    RoomServiceOutlined,
    SailingOutlined,
    StadiumOutlined,
    TourOutlined,
    TransferWithinAStationOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import axios from "axios";
import { flatten, isNumber } from "lodash";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartProductCardName } from "./CartProductCardName";
import { CartPoiIcon } from "./CartPoiIcon";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { CartManualProductCardDetails } from "./CartManualProductCardDetails";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartProductIconText } from "./CartProductIconText";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderContext } from "./utils/providerContext";
import { useGetPrice } from "./utils/getPrice";
import { useGetTaxesPrice } from "./utils/getTaxesPrice";
import { useShowError } from "../Utils/showError";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { findProductAssociatedItineraryStep } from "./utils/findProductAssociatedItineraryStep";
import { insertDivider } from "./utils/insertDivider";
import { transformStepInputsToGroups } from "../Itinerary/utils/transformStepInputsToGroups";
import { checkIfOnRoad } from "./utils/checkIfOnRoad";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    product: ManualProduct
} & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/destination_default.jpg';

export function CartManualProductCard(props: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date)
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const user = useSelector((state: AppState) => state.user?.user);
    const [editTime, setEditTime] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const [loading, setLoading] = useState(false);
    const { found, iti_error } = useMemo(() => {
        const result = findProductItineraryError(
            'manual',
            {
                product: props.product,
                itinerary: itinerary_list,
                stackInfos: trip?.stack_info ?? null
            }
        );
        if (!result) {
            return { found: true, iti_error: null };
        }
        return result;
    }, [
        itinerary_list,
        props.product,
        trip
    ]);
    const pictures: string[] = useMemo(() => {
        if (props.product.hide_pictures) {
            return [DEFAULT_PICTURE];
        }

        if ((props.product.overload_pictures?.length ?? 0) > 0) {
            return props.product.overload_pictures?.map((item) => {
                return [
                    item.url,
                    item.thumbnail_big,
                    item.thumbnail_medium,
                    item.thumbnail_little,
                    DEFAULT_PICTURE
                ].find((item) => {
                    return (item?.length ?? 0) > 0;
                }) ?? '';
            }) ?? [];
        } else if (props.product.picture) {
            return [
                [
                    props.product.picture.url,
                    props.product.picture.thumbnail_big,
                    props.product.picture.thumbnail_medium,
                    props.product.picture.thumbnail_little,
                    DEFAULT_PICTURE
                ].find((item) => {
                    return (item?.length ?? 0) > 0;
                }) ?? ''
            ];
        }

        return [DEFAULT_PICTURE];
    }, [props.product]);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.product.provider;
        })?.provider;
    }, [props.product, providers, manualProviders]);
    const typeIcon = useMemo(() => {
        let typeIcon: React.FunctionComponent<SvgIconProps> = BuildOutlined;

        switch (props.product.product_type) {
            case 12: {
                typeIcon = props.product.poi_type ?
                    CartPoiIcon :
                    VisibilityOutlined;
                break;
            }
            case 8: typeIcon = HealthAndSafetyOutlined; break;
            case 11: typeIcon = InventoryOutlined; break;
            case -1: typeIcon = QrCodeOutlined; break;
            case 7: typeIcon = HotelOutlined; break;
            case 17: typeIcon = ImportContactsOutlined; break;
            case 9: typeIcon = SailingOutlined; break;
            case 1: typeIcon = StadiumOutlined; break;
            case 13: typeIcon = DirectionsBoatOutlined; break;
            case 16: typeIcon = FolderSpecialOutlined; break;
            case 10: typeIcon = TourOutlined; break;
            case 0: typeIcon = RoomServiceOutlined; break;
            case 18: typeIcon = AttachMoneyOutlined; break;
            case 3: typeIcon = RestaurantOutlined; break;
            case 5: typeIcon = DirectionsTransitOutlined; break;
            case 4: typeIcon = TransferWithinAStationOutlined; break;
            case 2: typeIcon = DirectionsCarOutlined; break;
            case 61: typeIcon = FlightOutlined; break;
            case 20: typeIcon = BadgeOutlined; break;
            case 21: typeIcon = MarginOutlined; break;
            case 22: typeIcon = PointOfSaleOutlined; break;
        }

        return typeIcon;
    }, [props.product]);
    const isOnRoad = useMemo(() => {
        const groups = transformStepInputsToGroups(itinerary_list);
        const searchableItinerary = isProductPackaged({
            product: props.product,
            stackInfos: trip?.stack_info ?? null
        }) ?
            flatten(
                groups.filter((item) => {
                    return item[0] &&
                        (
                            isNumber(item[0].circuit) ||
                            isNumber(item[0].iti_type)
                        ) &&
                        item[0].circuit === props.product.from_circuit;
                })
            ) :
            itinerary_list;
        const steps = searchableItinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
        const associatedStep = findProductAssociatedItineraryStep({
            type: 'start',
            itinerary: steps,
            product: props.product,
            granularity: 'seconds'
        });

        if (
            [4, 12].includes(props.product.product_type) &&
            associatedStep
        ) {
            return checkIfOnRoad({
                type: props.product.product_type === 4 ?
                    'transfer' :
                    'poi',
                itinerary: steps,
                step: associatedStep,
                product: props.product
            });
        }

        return false;
    }, [props.product, itinerary_list]);
    const getPrice = useGetPrice();
    const getTaxesPrice = useGetTaxesPrice();
    const showError = useShowError();
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            dispatch({
                type: 'CART_EDIT_MANUAL_ITEM',
                payload: product
            });
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const totalCost = { ...getPrice(props.product.prices) };
    const providerContext = useContext(ProviderContext);
    totalCost.cost += getTaxesPrice(props.product.taxes ?? '0');
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.product.id}/`,
            data: {
                traveler_modification: null
            }
        }).then((response) => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch((error) => {
            console.log(error);
        });
    };

    const onChangeBookingReference = (reference: string) => {
        if (
            providerContext.module === 'booking' &&
            providerContext.tripId &&
            providerContext.version
        ) {
            changeBookingStatus({
                tripId: providerContext.tripId,
                version: providerContext.version,
                productId: props.product.id,
                ref: reference,
                status: props.product.booking_status?.status_booking ?? null,
                isManual: true
            });
        }
    }

    return (
        <>
            <CartProductCard
                type="manual"
                item={props.product}
                mainContent={
                    <Stack>
                        <CartProductCardName
                            type="manual"
                            content={
                                <>
                                    {
                                        (() => {
                                            const localization = props.product.localization?.find((item) => {
                                                return item.locale === locale;
                                            });
                                            return (localization?.name.trim().length ?? 0) > 0 ?
                                                localization?.name :
                                                props.product.name;
                                        })()
                                    }{' '}
                                    {
                                        (props.product.product_type === 2 || props.product.product_type === 4) &&
                                        props.product.vehicle_code &&
                                        `(${props.product.vehicle_code})`
                                    }
                                </>
                            }
                            modifiedName={
                                (() => {
                                    const localization = props.product.localization?.find((item) => {
                                        return item.locale === locale;
                                    });
                                    return (localization?.name.trim().length ?? 0) > 0 ?
                                        localization?.name ?? '' :
                                        props.product.name ?? '';
                                })()
                            }
                            providerName={props.product.name ?? ''}
                            localization={props.product.localization ?? []}
                            id={props.product.id}
                            isCustom={props.product.is_custom}
                            startIcon={typeIcon}
                            isProposition={props.product.provider_created}
                        />
                        {
                            props.product.poi_type &&
                            <CartProductIconText
                                text={t('global.poi')}
                                startIcon={Info}
                                color="#FF9C37"
                            />
                        }
                        {
                            props.product.start_destination &&
                            [12].includes(props.product.product_type) &&
                            <CartProductIconText
                                text={
                                    <>
                                        {
                                            props.product.start_destination.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            props.product.start_destination.data?.international_name ??
                                            ''
                                        }
                                    </>
                                }
                                startIcon={RoomOutlined}
                            />

                        }
                        {
                            (props.product.product_type === 2 || props.product.product_type === 4) &&
                            <>
                                {
                                    (props.product.address_pickup || props.product.station_name_pickup) &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.pickup-agency')} :{' '}
                                                {props.product.address_pickup && props.product.address_pickup}
                                                {props.product.address_pickup && props.product.station_name_pickup && ', '}
                                                {props.product.station_name_pickup && props.product.station_name_pickup}
                                            </>
                                        }
                                        startIcon={RoomOutlined}
                                    />
                                }
                                {
                                    (props.product.address_return || props.product.station_name_return) &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.return-agency')} :{' '}
                                                {props.product.address_return && props.product.address_return}
                                                {props.product.address_return && props.product.station_name_return && ', '}
                                                {props.product.station_name_return && props.product.station_name_return}
                                            </>
                                        }
                                        startIcon={RoomOutlined}
                                    />
                                }
                            </>
                        }
                        {
                            isOnRoad &&
                            <CartProductIconText
                                text={
                                    t(
                                        'cart-material.cart-construction-on-road',
                                        {
                                            from: (
                                                isOnRoad[0].destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                isOnRoad[0].destination?.data?.name ??
                                                isOnRoad[0].destination?.data?.international_name
                                            )?.split(',')[0],
                                            to: (
                                                isOnRoad[1].destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                isOnRoad[1].destination?.data?.name ??
                                                isOnRoad[1].destination?.data?.international_name
                                            )?.split(',')[0]
                                        }
                                    )
                                }
                                startIcon={ExploreOutlined}
                                color="#FF8100"
                            />
                        }
                        {
                            ![18, 20, 21].includes(props.product.product_type) &&
                            window.moment.utc(props.product.start_date).isValid() &&
                            <CartProductIconText
                                text={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <div>
                                            {
                                                window.moment.utc(props.product.end_date).isValid() ?
                                                    t(
                                                        'cart-material.cart-construction-products-table-date',
                                                        {
                                                            from: window.moment.utc(props.product.start_date).format('LLL'),
                                                            to: window.moment.utc(props.product.end_date).format('LLL')
                                                        }
                                                    ) :
                                                    window.moment.utc(props.product.start_date).format('LLL')
                                            }
                                        </div>
                                        {
                                            !providerContext.module &&
                                            (
                                                quotation_code !== 'visiteurs' ||
                                                user?.client_full?.type !== 2
                                            ) &&
                                            <IconButton size="small" onClick={() => setEditTime(true)}>
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        }
                                    </Stack>
                                }
                                startIcon={DateRangeOutlined}
                            />
                        }
                        <Stack
                            direction={{ md: 'row' }}
                            spacing={{ md: 2 }}
                        >
                            {
                                props.product.group_passenger &&
                                <CartProductIconText
                                    text={
                                        [
                                            t(
                                                'roadbook.adults-count',
                                                {
                                                    count: props.product.group_passenger.travelers_list?.filter((traveler) => {
                                                        const result = window.moment.utc(tripEndDate).diff(
                                                            window.moment.utc(traveler.birth_date),
                                                            'years'
                                                        );
                                                        return result >= 18;
                                                    }).length ?? 0
                                                }
                                            ),
                                            (() => {
                                                const childrenCount = props.product.group_passenger.travelers_list?.filter((traveler) => {
                                                    return window.moment.utc(tripEndDate).diff(
                                                        window.moment.utc(traveler.birth_date),
                                                        'years'
                                                    ) < 18;
                                                }).length ?? 0;
                                                return childrenCount > 0 ?
                                                    t(
                                                        'roadbook.children-count',
                                                        { count: childrenCount }
                                                    ) :
                                                    null;
                                            })()
                                        ].filter((item) => item).join(', ')
                                    }
                                    startIcon={PeopleOutlineOutlined}
                                />
                            }
                        </Stack>
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t('cart-material.cart-construction-amount')}
                        </Typography>
                        <ProviderQuotationPriceEdit
                            productId={props.product.id}
                            price={
                                (
                                    providerContext.module ?
                                        totalCost.purchaseCost :
                                        totalCost.cost
                                ).toString()
                            }
                            open={editPrice}
                            bothEntitiesAccepted={
                                props.product.provider_accepted &&
                                props.product.agent_accepted
                            }
                            providerWantsModification={props.product.provider_want_modification}
                            onOpen={() => setEditPrice(true)}
                            onClose={() => setEditPrice(false)}
                        >
                            <Typography fontWeight="bold">
                                {
                                    new Intl.NumberFormat(
                                        i18n.language,
                                        {
                                            style: 'currency',
                                            currency: providerContext.module ?
                                                totalCost.purchaseCurrency?.iso_code ?? 'EUR' :
                                                totalCost.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(
                                        providerContext.module ?
                                            totalCost.purchaseCost :
                                            totalCost.cost
                                    )
                                }
                            </Typography>
                        </ProviderQuotationPriceEdit>
                        <CartProductCardProviderLogo
                            logoUrl={
                                provider?.logo?.url ??
                                provider?.logo?.thumbnail_big ??
                                provider?.logo?.thumbnail_medium ??
                                provider?.logo?.thumbnail_little ??
                                null
                            }
                            name={provider?.name ?? null}
                        />
                    </Stack>
                }
                footer={(providerActions, providerInfo) => (
                    <>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {
                                insertDivider(
                                    [
                                        !isProductPackaged({
                                            product: props.product,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <CartConstructionProductsTableItemStatus
                                                type="manual"
                                                item={props.product}
                                                bookingProcessState={props.product.booking_process_state}
                                                bookingStatus={props.product.booking_status}
                                                agencyNeedToBook={props.product.agency_need_to_book}
                                            />
                                            {
                                                !trip?.stack_info?.find((item) => {
                                                    return isProductSamePackage(
                                                        {
                                                            stack_number: item.stack_number,
                                                            stack_info_id: item.is_custom && !props.product.stack_info_id ?
                                                                props.product.stack_info_id :
                                                                item.id
                                                        },
                                                        {
                                                            stack_number: props.product.stack_number,
                                                            stack_info_id: props.product.stack_info_id
                                                        }
                                                    );
                                                })?.booking_status?.item_reference &&
                                                (
                                                    props.product.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                    props.product.booking_status?.status_booking === StatusBooking.PENDING ||
                                                    props.product.booking_status?.status_booking === StatusBooking.WAITING
                                                ) &&
                                                <ProviderBookingReferenceEdit
                                                    open={editBookingReference}
                                                    productId={props.product.id}
                                                    reference={props.product.booking_status?.item_reference ?? ''}
                                                    onOpen={() => setEditBookingReference(true)}
                                                    onClose={() => setEditBookingReference(false)}
                                                    onSave={onChangeBookingReference}
                                                >
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.product.booking_status?.item_reference }
                                                            )
                                                        }
                                                    </Typography>
                                                </ProviderBookingReferenceEdit>
                                            }
                                        </>,
                                        providerActions,
                                        !providerContext.module &&
                                        !isProductPackaged({
                                            product: props.product,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        (
                                            user?.client_full?.type !== 2 ||
                                            props.product.creator?.client?.type === user.client_full.type
                                        ) &&
                                        <>
                                            {
                                                props.product.auto_product === null ?
                                                    <Chip
                                                        label={t('accommodation.manual')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<BuildCircle color="inherit" />}
                                                    /> :
                                                    <Chip
                                                        label={t('cart-material.annex')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#f5823b',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CardGiftcard color="inherit" />}
                                                    />
                                            }
                                        </>,
                                        user?.client_full?.type !== 2 &&
                                        props.product.traveler_modification &&
                                        <Chip
                                            color="warning"
                                            label={
                                                props.product.traveler_modification === 'SET_IN_TRIP' ?
                                                    t('cart-material.add-to-cart-traveler') :
                                                    t('cart-material.add-to-option-traveler')
                                            }
                                            size="small"
                                            icon={<ErrorOutline color="inherit" />}
                                            onDelete={confirmTravelerModification}
                                        />,
                                        (
                                            props.product.product_type === 2 ||
                                            props.product.product_type === 4
                                        ) &&
                                        props.product.one_way_fees &&
                                        parseFloat(props.product.one_way_fees) !== 0 &&
                                        <Tooltip title={t('cart-material.cart-construction-see-in-details')}>
                                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                                <CartProductIconText
                                                    text={t('cart-material.cart-construction-resort-fees')}
                                                    startIcon={ReportProblemOutlined}
                                                    color="#FF8100"
                                                />
                                            </Box>
                                        </Tooltip>,
                                        !providerContext.module &&
                                        props.product.moved_with_recalc &&
                                        (
                                            quotation_code !== 'visiteurs' ||
                                            user?.client_full?.type !== 2
                                        ) &&
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t('cart-material.cart-construction-recalculated-manual-product')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>,
                                        props.product.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                        props.product.on_request &&
                                        <Typography variant="caption">
                                            {t('cart-material.on-request')}
                                        </Typography>
                                    ]
                                )
                            }
                        </Stack>
                        {providerInfo}
                    </>
                )}
                alerts={
                    <Collapse in={!found || !!iti_error} unmountOnExit>
                        <CardContent
                            sx={{
                                borderTop: !found || !!iti_error ?
                                    '1px solid rgba(0, 0, 0, 0.25)' :
                                    undefined
                            }}
                        >
                            <Stack spacing={1}>
                                {
                                    iti_error &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.itinerary-error-1')}{' '}
                                        {
                                            iti_error.destination?.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            iti_error.destination?.data?.name ??
                                            iti_error.destination?.data?.international_name
                                        } {t('global.from')}{' '}
                                        {window.moment.utc(iti_error.start_date).format([0, 7].includes(props.product.product_type) ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm')}{' '}
                                        {t('global.to')} {window.moment.utc(iti_error.end_date).format([0, 7].includes(props.product.product_type) ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm')}.{' '}
                                        {t('cart-material.itinerary-error-2')}.
                                    </Alert>
                                }
                                {
                                    !found &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.no-destination-found')}
                                    </Alert>
                                }
                            </Stack>
                        </CardContent>
                    </Collapse>
                }
                margin={<MarginWrapper product={props.product} />}
                pictures={pictures}
                alwaysOpen={props.alwaysOpen}
                disablePictures={
                    ![0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19, 23].includes(props.product.product_type)
                }
            >
                <CartManualProductCardDetails manual_product={props.product} />
            </CartProductCard>
            {
                user?.client_full?.type !== 2 &&
                editTime &&
                <CartClientTimeModal
                    type="manual"
                    product={props.product}
                    onClose={() => setEditTime(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

type MarginWrapperProps = {
    product: ManualProduct
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                (
                    !isProductPackaged({
                        product: props.product,
                        stackInfos: trip?.stack_info ?? null
                    }) ||
                    !props.product.is_stack_price
                )
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="manual"
                    item={props.product}
                />
            </CardContent>
        </Collapse>
    );
}
