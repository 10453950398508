import { createContext } from "react";
import { ProviderQuotationProductStatus } from "../../ProviderQuotation/objects/providerQuotationProductStatus";

type Values = (
    ({
        module: 'quotation',
        statuses: {
            [key: number]: {
                id: number,
                isCustom: boolean,
                type: string,
                status: ProviderQuotationProductStatus | null,
                providerComment: string | null
            }
        },
        onChangePrice: (productId: number, price: number) => void,
        onChangeStatus: (
            key: number,
            status: ProviderQuotationProductStatus,
            providerComment: string | null
        ) => void,
        onOpenManualProductForm: () => void,
        onChangeCounterOfferProduct: (id: number) => void,
        onUnlockPriceChange: (priceId: number) => void
    } | {
        module: 'booking',
    }) & {
        priceType: 'manual' | 'products-total',
        tripId: number,
        version: number,
        providerId: number,
        tripToken: string
    }
) | {
    module: null
}

const initialValue: Values = {
    module: null
}

export const ProviderContext = createContext<Values>(initialValue);
