import React, { useContext, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Badge,
    Box,
    Chip,
    Collapse,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {
    AttachMoney,
    BuildCircle,
    CardGiftcard,
    DirectionsBoat,
    DirectionsCar,
    DirectionsTransit,
    DriveEta,
    ErrorOutline,
    Flight,
    FolderSpecial,
    HealthAndSafety,
    Hotel,
    ImportContacts,
    Inventory,
    Margin,
    MoreVert,
    PointOfSale,
    QrCode,
    ReportProblem,
    Restaurant,
    RoomService,
    Sailing,
    Stadium,
    Tour,
    Visibility
} from "@mui/icons-material";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { useTripDays } from "./utils/tripDays";
import { useGetPrice } from "./utils/getPrice";
import { useProductDays } from "./utils/productDays";
import { useManualProductAlerts } from "./utils/manualProductAlerts";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import GetCookie from "../Common/Functions/GetCookie";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    item: ManualProduct
}

export function CartConstructionProductsTableManualItem(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const user = useSelector((state: AppState) => state.user.user);
    const [openMenu, setOpenMenu] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.item.provider;
        })?.provider;
    }, [props.item, providers, manualProviders]);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const tripDays = useTripDays();
    const getPrice = useGetPrice();
    const getDays = useProductDays();
    const startDate = window.moment.utc(props.item.start_date);
    const endDate = window.moment.utc(props.item.end_date);
    const alerts = useManualProductAlerts(props.item);
    const price = getPrice(props.item.prices);
    const days = getDays(props.item.start_date, props.item.end_date);
    const providerContext = useContext(ProviderContext);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    let type = '';

    switch (props.item.product_type) {
        case 12: type = t('providers.activities'); break;
        case 8: type = t('providers.insurances'); break;
        case 11: {
            if (!['cercledesvoyages', 'verdie', 'continentsinsolites', 'terreslointaines'].includes(quotation_code)) {
                type = t('providers.other_product');
            } else {
                type = t('providers.other_visible_product');
            }
            break;
        }
        case -1: type = t('providers.other_invisible_product'); break;
        case 7: type = t('providers.accommodations'); break;
        case 17: type = t('providers.travel_book'); break;
        case 9: type = t('providers.cruises'); break;
        case 1: type = t('providers.excursions'); break;
        case 13: type = t('providers.ferries'); break;
        case 16: {
            if (!['cercledesvoyages', 'continentsinsolites', 'terreslointaines'].includes(quotation_code)) {
                type = t('providers.administration_fees');
            } else {
                type = t('providers.administration_fees_other');
            }
            break;
        }
        case 10: type = t('providers.touristic_guide'); break;
        case 0: type = t('providers.hotels'); break;
        case 18: type = t('providers.reduction'); break;
        case 3: type = t('providers.restaurant'); break;
        case 5: type = t('providers.trains'); break;
        case 4: type = t('providers.transfers'); break;
        case 2: type = t('providers.rental_car'); break;
        case 61: type = t('providers.manual_flight'); break;
        case 20: type = t("header.adjustment_product_name"); break;
        case 21: type = t("providers.manual_margin_adjustment"); break;
        case 22: type = t("providers.tva_margin"); break;
    }

    let typeIcon: JSX.Element | undefined;

    switch (props.item.product_type) {
        case 12: typeIcon = <Visibility />; break;
        case 8: typeIcon = <HealthAndSafety />; break;
        case 11: typeIcon = <Inventory />; break;
        case -1: typeIcon = <QrCode />; break;
        case 7: typeIcon = <Hotel />; break;
        case 17: typeIcon = <ImportContacts />; break;
        case 9: typeIcon = <Sailing />; break;
        case 1: typeIcon = <Stadium />; break;
        case 13: typeIcon = <DirectionsBoat />; break;
        case 16: typeIcon = <FolderSpecial />; break;
        case 10: typeIcon = <Tour />; break;
        case 0: typeIcon = <RoomService />; break;
        case 18: typeIcon = <AttachMoney />; break;
        case 3: typeIcon = <Restaurant />; break;
        case 5: typeIcon = <DirectionsTransit />; break;
        case 4: typeIcon = <DriveEta />; break;
        case 2: typeIcon = <DirectionsCar />; break;
        case 61: typeIcon = <Flight />; break;
        case 20: typeIcon = <Badge />; break;
        case 21: typeIcon = <Margin />; break;
        case 22: typeIcon = <PointOfSale />; break;
    }

    const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        update(
            version,
            {
                id: props.item.id,
                type: 'manual',
                isCustom: props.item.is_custom
            },
            { traveler_modification: null }
        );
    };


    return (
        <>
            <CartConstructionProductsTableItem
                type="manual"
                item={props.item}
            >
                <LightTooltip
                    title={alerts}
                    onClick={(event) => event.stopPropagation()}
                    placement="top"
                    arrow
                >
                    <TableCell sx={{ verticalAlign: 'middle', paddingRight: 0 }}>
                        {
                            alerts.length > 0 &&
                            <ReportProblem color="warning" />
                        }
                    </TableCell>
                </LightTooltip>
                <TableCell>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        flexWrap="nowrap"
                    >
                        <LightTooltip title={type} placement="top" arrow>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                flexWrap="nowrap"
                            >
                                {typeIcon}
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{
                                        "maxWidth": 100,
                                        "display": '-webkit-box',
                                        '-webkit-line-clamp': '1',
                                        '-webkit-box-orient': 'vertical',
                                        "overflow": 'hidden',
                                        "textOverflow": "ellipsis"
                                    }}
                                >
                                    {type}
                                </Typography>
                            </Stack>
                        </LightTooltip>
                        {
                            !isProductPackaged({
                                product: props.item,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                            (
                                user?.client_full?.type !== 2 ||
                                props.item.creator?.client?.type === user.client_full.type
                            ) &&
                            <>
                                {
                                    props.item.auto_product === null &&
                                    <Chip
                                        label={t('accommodation.manual')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<BuildCircle color="inherit" />}
                                    />
                                }
                                {
                                    props.item.auto_product &&
                                    <Chip
                                        label={t('cart-material.annex')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#f5823b',
                                            color: '#fff'
                                        }}
                                        icon={<CardGiftcard color="inherit" />}
                                    />
                                }
                            </>
                        }
                    </Stack>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{
                            position: 'absolute',
                            marginTop: 1,
                            opacity: 0.7
                        }}
                    >
                        {
                            ![18, 20, 21].includes(props.item.product_type) &&
                            <>
                                {
                                    days.length > 0 &&
                                    <>
                                        <LightTooltip
                                            title={
                                                (!days[0] || !tripDays.includes(days[0])) ?
                                                    t(
                                                        'cart-material.cart-construction-incorrect-product-days',
                                                        { day: tripDays.join(', ') }
                                                    ) :
                                                    ''
                                            }
                                            arrow
                                        >
                                            <Box
                                                sx={
                                                    (!days[0] || !tripDays.includes(days[0])) ?
                                                        {
                                                            backgroundColor: '#ff0000',
                                                            color: '#fff'
                                                        } :
                                                        undefined
                                                }
                                                component="span"
                                            >
                                                {
                                                    days[0] !== days[days.length - 1] ?
                                                        t(
                                                            'cart-material.cart-construction-day-from-to',
                                                            { from: days[0], to: days[days.length - 1] }
                                                        ) :
                                                        t(
                                                            'cart-material.cart-construction-day',
                                                            { day: days[0] }
                                                        )
                                                }
                                            </Box>
                                        </LightTooltip>
                                        {' '}-{' '}
                                    </>
                                }
                                {
                                    (startDate.isValid() || endDate.isValid()) &&
                                    t(
                                        'cart-material.cart-construction-products-table-date',
                                        {
                                            from: startDate.isValid() ? startDate.format('L') : null,
                                            to: endDate.isValid() ? endDate.format('L') : null
                                        }
                                    )
                                }
                            </>
                        }
                        <Stack
                            display="inline-flex"
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ marginLeft: 1.5 }}
                        >
                            <CartConstructionProductsTableItemProviderQuotationStatus
                                item={props.item}
                            />
                        </Stack>
                        {
                            props.item.traveler_modification &&
                            <Chip
                                color="warning"
                                label={
                                    props.item.traveler_modification === 'SET_IN_TRIP' ?
                                        t('cart-material.add-to-cart-traveler') :
                                        t('cart-material.add-to-option-traveler')
                                }
                                size="small"
                                icon={<ErrorOutline color="inherit" />}
                                onDelete={onDeleteTravelerModificationAlert}
                            />
                        }
                    </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                    -
                </TableCell>
                <LightTooltip
                    title={
                        <>
                            {
                                (() => {
                                    const localization = props.item.localization?.find((item) => {
                                        return item.locale === locale;
                                    });
                                    return (localization?.name.trim().length ?? 0) > 0 ?
                                        localization?.name :
                                        props.item.name;
                                })()
                            }{' '}
                            {
                                (props.item.product_type === 2 || props.item.product_type === 4) &&
                                props.item.vehicle_code &&
                                `(${props.item.vehicle_code})`
                            }
                        </>
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                (() => {
                                    const localization = props.item.localization?.find((item) => {
                                        return item.locale === locale;
                                    });
                                    return (localization?.name.trim().length ?? 0) > 0 ?
                                        localization?.name :
                                        props.item.name;
                                })()
                            }{' '}
                            {
                                (props.item.product_type === 2 || props.item.product_type === 4) &&
                                props.item.vehicle_code &&
                                `(${props.item.vehicle_code})`
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item,
                            stackInfos: trip?.stack_info ?? null
                        }) &&
                        <CartProductCardProviderLogo
                            logoUrl={
                                provider?.logo?.url ??
                                provider?.logo?.thumbnail_big ??
                                provider?.logo?.thumbnail_medium ??
                                provider?.logo?.thumbnail_little ??
                                null
                            }
                            name={provider?.name ?? null}
                            width={48}
                            height={32}
                        />
                    }
                </TableCell>
                <LightTooltip
                    title={
                        props.item.booking_status?.item_reference ?
                            t(
                                'cart-material.cart-construction-reference',
                                { ref: props.item.booking_status?.item_reference }
                            ) :
                            ''
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.booking_status?.item_reference &&
                                t(
                                    'cart-material.cart-construction-reference',
                                    { ref: props.item.booking_status?.item_reference }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <CartConstructionProductsTableItemStatus
                            type="manual"
                            item={props.item}
                            bookingProcessState={props.item.booking_process_state}
                            bookingStatus={props.item.booking_status}
                            agencyNeedToBook={props.item.agency_need_to_book}
                        />
                    }
                </TableCell>
                <TableCell>
                    {
                        !isProductPackaged({
                            product: props.item,
                            stackInfos: trip?.stack_info ?? null
                        }) ?
                            <div className="ellipsis">
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: price.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(price.cost)
                                }
                            </div> :
                            <CartPackageItemChangeProviderQuotationStatusButtons
                                item={{
                                    ...props.item,
                                    stackNumber: props.item.stack_number,
                                    providerId: props.item.provider,
                                    type: 'manual',
                                    isCustom: props.item.is_custom
                                }}
                            />
                    }
                </TableCell>
                <TableCell>
                    <IconButton
                        ref={menuButtonRef}
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenMenu(true);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <CartConstructionProductsTableItemMenu
                        type="manual"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                </TableCell>
            </CartConstructionProductsTableItem>
            <TableRow className="cart-construction-table-item-margin-row">
                <MarginWrapper item={props.item} />
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    item: ManualProduct
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    !isProductPackaged({
                        product: props.item,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                <CartConstructionProductsTableItemMargin
                    type="manual"
                    item={props.item}
                />
            </Collapse>
        </TableCell>
    );
}
